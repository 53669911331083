import { render, staticRenderFns } from "./Note.vue?vue&type=template&id=7b4a33f6&scoped=true&"
var script = {}
import style0 from "./Note.vue?vue&type=style&index=0&id=7b4a33f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4a33f6",
  null
  
)

export default component.exports